<template>
  <!--定损员列表-->
  <div>
    <div v-if="customdetails == false">
      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="custom" style="margin-left: 20px">
                <el-button class="button" type="primary" @click="add()">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">添加</span>
                </el-button>
                <!--<el-button class="custom-button">-->
                <!--  <i class="el-icon-devops" />-->
                <!--  <span style="vertical-align: middle">批量导入</span>-->
                <!--</el-button>-->
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >
              <!--自定义列显示 示例-->
              <template v-slot:authentication_status= "{ scope }">
                <div v-if="scope.row.authentication_status == 0">
                <span style="
                    width: 8px;
                    height: 8px;
                    background: #ff9e1c;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 10px;
                 "></span>
                  <span>待认证</span>
                </div>
                <div v-else>
                <span style="
                    width: 8px;
                    height: 8px;
                    background: #25eb61;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 10px;
                 "></span>
                  <span>已认证</span>
                </div>
              </template>


              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <!--<el-link :underline="false" type="warning" @click="Todetails(scope.row)" v-auths="[`${$config.uniquePrefix}channelcustom:list:info`]">详情</el-link>-->
                <el-link v-auths="[`${$config.uniquePrefix}ordinary_custom:list:status`]" :underline="false" type="danger" v-if="scope.row.status === 0" @click="Enable(scope.row)">停用</el-link>
                <el-link v-auths="[`${$config.uniquePrefix}ordinary_custom:list:status`]" :underline="false" type="danger" v-if="scope.row.status === 1" style="margin-right: 10px;" @click="Disable(scope.row)">启用</el-link>
                <el-link :underline="false" @click="del(scope.row)" v-auths="[`${$config.uniquePrefix}ordinary_custom:list:delete`]">删除</el-link>
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

    <!-- 添加 -->
    <ServiceProvider v-if="showadd === true" :data="addcurrent" :visible.sync="showadd" @done="getList"/>

    <!-- 客户详情模块 -->
    <!--<custom-details v-if="customdetails === true" :dict="dict" :data="Editdata" :id="pid" @showDetalis="ShowDetalis"></custom-details>-->

  </div>
</template>

<script>
// 引入订单详情组件
// import CustomDetails from '../details/index.vue'
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  customerstatus, Delcustomers,
  getcustomers
} from '@/api/custom'

// 添加渠道客户
import ServiceProvider from './components/add.vue'
import Vue from "vue";

export default {
  // 组件生效
  components: {
    // CustomDetails,
    PackageSortElement,
    ServiceProvider
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "系统编号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true,
          fixed: 'left'
        },
        {
          label: "所属公司",
          prop: "",
          isShow: true
        },
        {
          label: "姓名",
          prop: "",
          isShow: true
        },
        {
          label: "电话",
          prop: "",
          isShow: true
        },
        {
          label: "结算方式",
          prop: "",
          isShow: true
        },
        {
          label: "下单类型",
          prop: "",
          isShow: true
        },
        {
          label: "账号",
          prop: "phone",
          isShow: true
        },
        {
          label: "账号状态",
          prop: "",
          isShow: true
        },


        // {
        //   label: "客户名称",
        //   prop: "channel_name",
        //   isShow: true
        // },
        // {
        //   label: "合作服务商名称",
        //   prop: "facilitator_name",
        //   isShow: true
        // },
        // {
        //   label: "合作服务商编号",
        //   prop: "facilitator_code",
        //   isShow: true
        // },
        // {
        //   label: "合作开始日期",
        //   prop: "facilitator_time",
        //   isShow: true
        // },
        // {
        //   label: "合作状态",
        //   prop: "facilitator_status",
        //   isShow: true
        // },
        // {
        //   label: "结算方式",
        //   prop: "payments",
        //   isShow: true
        // },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true,
          fixed: 'right'
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //添加渠道客户
      addcurrent:null,
      showadd:false,

      //详情模块
      Editdata:null,
      customdetails:false,

      //字典
      dict: {
        type:{}
      },

      pid:0,

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 服务项目配置跳转过来的
    if(this.$route.query.id!= undefined){
      getcustomers(this.where).then(async (res) => {
        this.List = res.data.list;
        this.List.forEach(item => {
          if(item.id == this.$route.query.id){
            this.Editdata = item;
            this.pid = item.id;
          }
        })
        this.customdetails = false;
        this.$nextTick(() => {
          this.customdetails = true;
        })
      }).catch((res) => {
        this.$Message.error(res.msg);
      })
    }else {
      this.customdetails = false;
    }

    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '客户字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });
    console.log(this.dict)

    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      getcustomers(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 启用状态
    Enable(row){
      let that = this;
      this.$utils.confirm("确定执行此操作吗？", function () {
        // 执行一些需要的逻辑
        // 参数
        let params = {
          status: '1'
        }
        customerstatus(row.id,params).then(res => {
          if(res.code === 200){
            that.$message.success(res.msg);
            that.getList();
          }else {
            that.$message.error(res.msg)
          }
        }).catch(err => {
          that.$message.error(err.msg);
        })
      }, "确定", 'warning');
    },
    Disable(row){
      let that = this;
      this.$utils.confirm("确定执行此操作吗？", function () {
        // 执行一些需要的逻辑
        // 参数
        let params = {
          status: '0'
        }
        customerstatus(row.id,params).then(res => {
          if(res.code === 200){
            that.$message.success(res.msg);
            that.getList();
          }else {
            that.$message.error(res.msg)
          }
        }).catch(err => {
          that.$message.error(err.msg);
        })
      }, "确定", 'warning');
    },


    // 删除
    del(row){
      console.log(row);
      let that = this;
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        Delcustomers(row.id).then(res => {
          console.log(res)
          if(res.code === 200){
            that.$message.success(res.msg);
            that.getList();
          }else {
            that.$message.success(res.msg);
          }
        }).catch(e => {
          that.$message.error(e.msg)
        })
      }, "确定", 'warning');
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    // 跳转详情
    Todetails(row){
      this.pid = row.id;
      this.Editdata = row;
      //显示详情模块
      this.customdetails = true;
    },
    // 接收 详情子组件传递的值
    ShowDetalis(value){
      console.log(value)
      // 隐藏详情子组件
      this.customdetails = false;
      this.getList();
    },

    //点击添加
    add(){
      this.showadd = true;
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
